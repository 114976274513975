@import 'colours';

.editor-component-list {
  position: fixed;
  top: 0px;
  // to avoid flicker when first rendered
  right: -100vw;
  height: 100%;
  z-index: 500;

  .click-capture {
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  &-sidebar {
    z-index: 5;
    float: right;
    width: 440px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #454545;
  }

  &-sidebar-exit {
    float: right;
    margin: 3px 10px;
    font-size: 150%;
    color: #7D7D7D;

    &:hover {
      cursor: pointer;
    }
  }

  &-sidebar-header {
    border-bottom: 1px solid #CCCCCC;
  }

  &-sidebar-title-inner {
    padding: 20px 20px 0px;
  }

  &-sidebar-body-inner {
    padding-left: 20px;
  }

  &-sidebar-search {
    &-icon {
      position: relative;
      left: 380px;
      top: 33px;
      font-size: 130%;
      color: #B7B4B4;
    }

    &-field input {
      margin: 0 0 25px 20px;
      border-radius: 0;
      background-color: #F2F0F1;
    }
  }

  &-sidebar-list {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-sidebar-filter-half {
    float: left;
    width: 50%;
    padding: 18px 0px;
    text-align: center;
  }

  &-sidebar-filter-full {
    float: left;
    width: 50%;
    padding: 18px 0px;
    text-align: center;
  }

  &-sidebar-filter-single {
    width: 100%;
    padding: 18px 0px;
    background-color: #333c4e;
    color: #fff;
    text-align: center;
  }

  &-sidebar-filter button {
    background-color: #333c4e;
    color: #9cabc4;
    transition: all 0.3s;
    &:hover {
      background-color: #293141;
      color: #fff;
    }
    &.selected {
      background-color: #000;
      color: #fff;
    }
  }
}

.editor-component-list-item {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  text-align: left;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.3s;
  cursor: pointer;

  &.restricted {
    color: lightgray;
  }

  &.selected {
    background-color: #f1f1f1;
    &:hover {
      background-color: #f1f1f1;
    }
    &.restricted {
      color: black;
    }
  }

  &-details {
    padding-left: 75px;
  }

  &:hover {
    background-color: @ui-content-color;
  }

  &-inner {
    padding: 20px;
    min-height: 44px;
  }

  &-display-name {
    font-weight: @font-weight-bold;
  }

  &-version,
  &-name {
    margin-top: 6px;
    font-size: 11px;
  }

  &-overlay {
    top: 0;
    width: 100%;
    height: 100%;
  }

  &-overlay-inner {
    text-align: center;
    padding-bottom: 10px;
  }

  &-overlay-left {
    float: left;
  }

  &-overlay-right {
    float: right;
  }

  &-overlay-button {
    width: 20%;
  }

  &-overlay-button-inner {
    display: block;
    padding: 16px;
    border-right: 1px solid #454545;
  }

  &-icon {
    width: 55px;
    height: 40px;
    float: left;
    margin: 5px 20px 0 0;
    background-repeat: no-repeat;
    background-image: url("assets/icon-default.png");
    // core components, override above
    &.adapt-contrib- {
      &assessmentResults {
        background-image: url("assets/icon-assessment.png");
      }
      &blank {
        background-image: url("assets/icon-blank.png");
      }
      &accordion {
        background-image: url("assets/icon-accordion.png");
      }
      &graphic {
        background-image: url("assets/icon-graphic.png");
      }
      &gmcq {
        background-image: url("assets/icon-gmcq.png");
      }
      &hotgraphic {
        background-image: url("assets/icon-hot-graphic.png");
      }
      &matching {
        background-image: url("assets/icon-matching.png");
      }
      &media {
        background-image: url("assets/icon-media.png");
      }
      &mcq {
        background-image: url("assets/icon-mcq.png");
      }
      &narrative {
        background-image: url("assets/icon-narrative.png");
      }
      &slider {
        background-image: url("assets/icon-slider.png");
      }
      &text {
        background-image: url("assets/icon-text.png");
      }
      &textInput {
        background-image: url("assets/icon-text.png");
      }
    }
  }
}
