@import 'fonts';

.extension-checkbox-column {
  vertical-align: middle;
  text-align: center;
}

.extension-management {
  background-color: @white;
  border: 1px solid #d6f2f9;
  margin: 20px;
  font: @font-title;
  .tb-title {
    background-color: transparent;
    font-size: 110%;
    color: inherit;
    text-align: left;
    padding-top: 40px !important;
    padding-left: 20px !important;
    border: none;
  }
}
