.presetEdit {
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;

  .presetEdit-inner {
    background-color: @white;
    border-radius: 5px;
    margin: 0 auto;
    padding: 35px;
    max-height: 90%;
    min-width: 552px;
    position: relative;

    .button {
        display: inline-block;
        width: 115px;
        text-align: center;
        vertical-align: middle;
        &.close {
          position: absolute;
          right: 15px;
          top: 0px;
          width: 20px;
        }
    }

    .title {
      font-size: 18px;
      padding-bottom: 12px;
    }

    .description {
      padding-bottom: 25px;
    }

    .presets {
      .preset {
        padding-bottom: 10px;

        .nameEdit {
          display: none;

          .preset-error {
            color: red;
          }
        }

        .label,
        .nameEdit input {
          padding: 11px;
          margin-right: 15px;
          display: inline-block;
          width: 50%;
          border: 1px solid #f1f1f1;
          border-radius: 5px;
          vertical-align: middle;
          font-weight: @font-weight-bold;
          word-break: break-word;
        }
      }
    }
  }

  .screenlock {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
}
