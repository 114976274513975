/* Contains styling for columns */
.col-row {
    zoom: 1;
    display: flex;
    align-items: center;
    &:before, &:after {
        content: "\0020"; display: block; height: 0; overflow: hidden;
    }
    &:after {
        clear: both;
    }

    .col-centered {
        text-align:center;
    }

    /*Two column layout*/
    .col-1-2 {
        float:left;
        width:0%;
        min-height:1px;
    }
    .col-2-2 {
        float:left;
        width:50%;
        min-height:1px;
    }
    /*Three column layout*/
    .col-1-3 {
        float:left;
        width:0%;
        min-height:1px;
    }
    .col-2-3 {
        float:left;
        width:33.3%;
        min-height:1px;
    }
    .col-3-3 {
        float:left;
        width:66.6%;
        min-height:1px;
    }
    /*Four column layout*/
    .col-1-4,
    .col-2-4,
    .col-3-4,
    .col-4-4 {
        float:left;
        width:25%;
        min-height:1px;
    }

    /*Five column layout*/
    .col-1-5,
    .col-2-5,
    .col-3-5,
    .col-4-5,
    .col-5-5 {
        float:left;
        width:20%;
        min-height:1px;
    }

    /*Six column layout*/
    @col-6:16.6%;
    .col-1-6 {
        float:left;
        width:@col-6;
        min-height:1px;
    }
    .col-2-6 {
        float:left;
        width:(@col-6 * 2);
        min-height:1px;
    }
    .col-3-6 {
        float:left;
        width:(@col-6 * 3);
        min-height:1px;
    }
    .col-4-6 {
        float:left;
        width:(@col-6 * 4);
        min-height:1px;
    }
    .col-5-6 {
        float:left;
        width:(@col-6 * 5);
        min-height:1px;
    }
    .col-6-6 {
        float:left;
        width:(@col-6 * 6);
        min-height:1px;
    }

    /*Adjustable widths*/

    .col-100 {
        float:left;
        width:100%;
        min-height:1px;
    }

    .col-90 {
        float:left;
        width:90%;
        min-height:1px;
    }

    .col-80 {
        float:left;
        width:80%;
        min-height:1px;
    }

    .col-70 {
        float:left;
        width:70%;
        min-height:1px;
    }

    .col-60 {
        float:left;
        width:60%;
        min-height:1px;
    }

    .col-50 {
        float:left;
        width:50%;
        min-height:1px;
    }

    .col-40 {
        float:left;
        width:40%;
        min-height:1px;
    }

    .col-30 {
        float:left;
        width:30%;
        min-height:1px;
    }

    .col-20 {
        float:left;
        width:20%;
        min-height:1px;
    }

    .col-10 {
        float:left;
        width:10%;
        min-height:1px;
    }

    .col-25 {
        float:left;
        width:25%;
        min-height:1px;
    }

    .col-75 {
        float:left;
        width:75%;
        min-height:1px;
    }

    .col-6th {
        float:left;
        width:@col-6;
        min-height:1px;
    }

    .col-5 {
        float:left;
        width:5%;
        min-height:1px;
    }

    .col-15 {
        float:left;
        width:15%;
        min-height:1px;
    }

}
