@import 'colours';

@default-project-bg: url(assets/origami-project.jpg);

.projects {
  ul {
    list-style-type: none;
  }
  li {
    margin-bottom:30px;
  }
}

.projects-options-container {
  height: 30px;
}

ul.projects-options {
  float: right;
  color: #333C4E;
  padding-right: 20px;
  padding-top: 14px;

  a {
    text-decoration: none;
    color: #333C4E;
    &:hover {
      color: #15a4fa;
    }
  }

  li {
    float: left;
  }
}

.projects-inner {
  padding: 30px 30px 0px 0px;
}

.projects-list[data-layout=grid] {
    .project-list-item {
        margin-left: 25px;
        min-width: 200px;
        min-height: 341px;
        width: 22%;
        max-width:300px;
        display:inline-block;
        .project-image {
            width:100%;
            height:150px;
            background-image: @default-project-bg;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border: 1px solid #d6f2f9;
            border-left: none;
            border-right: none;
        }
        .project-details-last-updated,
        .project-details-createdBy {
          .projects-details-label {
            text-transform: uppercase;
          }
        }
        .project-details-createdBy,
        .project-details-tags {
            margin-top:8px;
        }
        .tag-container {
            display:none;
            position:absolute;
            width:100%;
            left:0;
            bottom:0px;
            padding:20px 0px;
            border-top:1px solid #eee;
            min-height: 119px;
        }
    }
}

.projects-list[data-layout=list] {
    margin-left:30px;

    .project-list-item {
        margin-left:0px;
        width:100%;
        .project-settings {
            border-bottom:1px solid #d6f2f9;
        }
        .project-image {
            width:40%;
            height:222px;
            background-image: @default-project-bg;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            float:left;
            border-right: 1px solid #d6f2f9;
        }
        .project-details {
            float:left;
            width:59%;
        }
        .project-details-tags {
            margin-top:8px;
        }

        .tag-container {
            display:block!important;
            opacity:1!important;
        }
        .projects-details-tags-button,
        .projects-details-tags-button-hide {
            display:none;
        }
    }
}

.projects-sidebar-filter-input {
    width:168px;
}

.projects-sidebar-filter-clear {
    padding:6px;
}

#projects-tags_tagsinput {
    display:inline-block;
    padding:4px;
    border:none;
}

.no-projects {
    text-align:center;
    font-size: 16px;
    margin-top: 10%;
    color: @tertiary-color;
}

.sidebar-search-tag {
    margin-top: 40px;
}

.sidebar-tag {
    text-align: left;

     &.selected span:first-child i:before{
        content:'\f205';
    }
}
