.asset-management-modal-tags.sidebar-filter {
  top: 60px;
  z-index: 5;
}

.asset-management-modal-new-asset-inner .form-group.tags .selectize-control {
  @item-padding: 4px 7px;

  .selectize-input {
    box-sizing: inherit;
    width: 90%;
    padding: 11px 10px;

    .item, .item.active,
    .option, .option.active {
      background-color: @secondary-color;
      background-image: none;
      border: none;
      border-radius: 0;
      text-shadow: none;
      padding: @item-padding;
    }
  }
  .selectize-dropdown {
    .option {
      padding: @item-padding;
      cursor: pointer;
      &[data-disabled="true"] { cursor: not-allowed; }
    }
    .create {
      .selectize-dropdown .option;
    }
  }
}
