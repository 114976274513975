[data-type="DisplayTitle"] {
  &:not(.unlocked) .fa-lock,
  &.unlocked .fa-unlink {
    display: none;
  }
  .field-editor input {
    width: calc(90% - 80px);
  }
}

.scaffold-display-title-lock,
.scaffold-display-title-clear {
  padding: 0;
  .fa {
    padding: 10px 12px;
  }
}
