@import 'colours';

.tb-title {
    background-color: #9cabc4;
    color: @white;
    text-align: center;
    border-bottom: 1px gray solid;
    padding: 24px 30px!important;
    border-top: 1px solid gray;
}

.tb-row {
  padding:20px 30px;
  border-bottom: 1px solid #efefef;

  .tb-col-inner {
    padding-right:10px;
    :not(.tb-heading)& {
      font-weight: @font-weight;
    }
  }
}

.tb-heading {
  padding: 35px 30px;
  h1, h2, h3, h4, h5, h6 {
    color: @primary-color;
  }
}
