.filters-typeahead {

	.tt-hint {
		width:200px;
		max-width:200px;
		padding:11px 10px;
	}
	.tt-input {
		width:200px;
		max-width:200px;
		padding:11px 10px;
	}

	.tt-dropdown-menu {
		background-color: @white;
		width:220px;	
		border:1px solid #ccc;
	}

	.tt-suggestion {
		padding:8px;
		&.tt-cursor {
			background-color:#e0e8f7;
		}
	}
}