form label {
    display:block;
    font-size:15px;
    margin-bottom:4px;
}

.form-group {
    margin-bottom:20px;
    padding-bottom:20px;
    border-bottom:1px solid #e3e3e3;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="phone"],
input[type="tel"],
input[type="number"],
input[type="datetime"],
input[type="date"],
input[type="search"],
input[type="datetime-local"],
textarea,
select[multiple="multiple"] {
    padding: 11px 10px;
    border-radius: 2px;
    border: solid 1px #ccc;
    font-size: 13px;
    width:90%;
    /*max-width:700px;*/
    &:focus {
        outline:none;
        border-color:#666;
        box-shadow:none;
    }
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

select {
    padding: 10px;
    min-width: 100px;
}

input:disabled, textarea:disabled {
    background-color:#e2e2e2;
}

.form-list {
    li {
        margin-bottom:20px;
        list-style: none;
        input, label {
            margin-bottom:0px;
            display:inline-block;
        }
        label {
            margin-left:10px;
        }
    }
}

.field-help {
    margin-bottom:4px;
    color: #666;
}

.max-fileupload-size {
  margin-top: 1rem;
  display: block;
}
