.field-editor {
  .cke_chrome {
    border-color: #ccc;
    border-radius: 2px;
    overflow: hidden;
    box-shadow: none;
    width: 93%;
  }

  .cke_top {
    background: #eee;
    border-color: #ccc;
    box-shadow: none;
  }

  .cke_bottom {
    background: @white;
    border-color: #ccc;
  }

  .cke_toolgroup {
    background-image: none;
    background: @white;
    border-color: #ccc;
  }
}
