.sweet-alert .sa-input-error {
  top: 24px !important;
}

.sidebar {
  button.short {
    height: 35px !important;
    padding: 0;
  }
}

.theming-inner {
  .error {
    position: inherit;
    padding: 30px 30px 0 30px;
    color: @alert-color;
    font-size: 17px;
    font-weight: @font-weight-bold;
    text-align: center;
    max-width: 800px;
  }
  .header {
    .form-container-style;
    .description {
      padding-bottom: 15px;
    }
    .tip {
      padding-top: 35px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: @font-weight-bold;
    margin-bottom: 30px;
  }
  button {
    margin-right: 5px;
  }
  .theme-selector {
    .tile {
      label {
        font-weight: @font-weight-bold;
        padding-right: 5px;
      }
      padding-right: 15px;
      display: inline-block;
      select {
        min-width: 150px;
      }
    }

    &.show-preset-select {
      select {
        width: 180px;
      }
    }
    .edit.btn.secondary {
      display: inline-block;
      padding: 7px;
      margin-left: 15px;
    }
  }

  .theme-customiser {
    .form-container {
      fieldset {
        &.empty-fieldset {
          display: none;
        }
      }
    }
  }
}
