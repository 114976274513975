@import 'colours';

.editor-view {

  .editor-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .editor-panel {
    float: right;
    width: 10%;
  }

  .editor {
    float: left;
    width: 90%;
    height: 100%;
    background-color: lightgrey;
  }

  .paste-zone {

    &.paste-zone-block,
    &.paste-zone-article {
      margin-bottom: 20px;
    }

    &-inner {
      padding: 20px 30px;
      border: 1px dashed lighten(@primary-color, 15%);
      border-radius: 3px;
    }

    &-component-left {
      width: 48%;
      float: left;
    }

    &-component-right {
      width: 48%;
      float: right;
    }

    &-component-full {
      margin-bottom: 20px;
    }

  }

  .paste-zone-available {
    background-color: #d6f3f9;
    border: 1px dashed #31b8cc;
  }

  .paste-zone-droppable {
    visibility: visible;
    background-color: #beebf5;
    border-style: solid;
  }

  .drop-only {
    display: none;
  }

  .component-full {
    float: left;
    width: 100%;
  }

  .page-article-components:after {
    clear: both;
    content: "";
    display: block;
  }

  .editor-item-sidebar {
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 100%;
    background: #454545;

    &.handle {
      cursor: move;
    }
  }

  .drag-helper {
    color: #DEDEDE;
    border: 1px solid #C9C9C9;
    background: #7A7A7A;
    padding: 10px;
    margin: 10px;
    cursor: move;
    min-width: 100px;
  }
}

.editor-item-settings {
    font-size: 16px;
    width: 100%;
    opacity: 1;
    background-color: transparent;
    transition: all 0.3s;

    a {
      text-decoration: none;
    }

    &:hover {
      cursor: move;
    }

    &-inner {
      button {
        padding: 10px;
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        box-sizing: content-box;
        text-align: center;
      }

      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 0 10px;

      .editor-delete-page-element {
        order: 2;
      }
      .editor-page-element-detail-inner {
        width: 100%;
        padding: 0 10px;
        overflow: hidden;
        order: 1;
        display: flex;
        align-items: center;
        .title {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.component-detail-inner {
          opacity: 0;  // we need the element to move the trash-icon to the right
        }
      }
      .component-move {
        order: 1;
      }
      .editor-collapse-article {
        order: 2;
      }
    }
}

.editor-item-settings button,
.editor-menu-item-settings button {
  color: @primary-color;
  transition: all 0.3s;
  &:hover {
    color:darken(@primary-color,5%);
  }
}

i.asset-selector {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

i.asset-clear {
  cursor: pointer;
}

.sweet-alert .key {
  font-weight: @font-weight-bold;
}

.page-content-syncing {
  &.block {
    min-height: 150px;
    .component {
      &-inner {
        display: none;
      }
    }
  }
}

.syncing {
  transform: scale(0.8, 0.8);
  opacity: 0.6;
}

.not-synced {
  transition:transform 0.3s cubic-bezier(0.8, 0.0, 0.2, 1), opacity 0.3s cubic-bezier(0.8, 0.0, 0.2, 1)!important;
  transform: scale(0.4, 0.4);
  opacity:0;
}
